<template>
  <div class="filter_block">
    <custom-input
        :delay="800"
        name="staff-passings-sort"
        label="Название"
        :inputWidth="100"
        cleanable
        v-model="searchParams.nameField"
    />
  </div>
  <div v-if="companies">
    <div v-if="shownData.length">
      <base-table
          v-if="shownData.length"
          :columns="organisationFields"
          :rows="shownData"
      />
      <table-pagination
          v-if="shownData.length"
          :key="updatePagination"
          :totalPages="1"
          :countOnPage="countOnPage"
          :total="totalResults ?? rowData.length"
          :count="countOnPage > shownData.length ? shownData.length : countOnPage"
          @changePage="changePage"
      />
    </div>
    <div v-else class="no_results">
      <p>Нет результатов</p>
    </div>
  </div>
  <div v-else class="table-preloader">
    <preloader/>
  </div>
</template>

<script>
import {reactive, ref} from "@vue/reactivity";
import {useStore} from "vuex";
import {computed, onBeforeMount, watch} from "@vue/runtime-core";
import Preloader from "../Technical/Preloader.vue";
import CustomInput from "../Forms/Fields/CustomInput.vue";
import sortData from "@/mixins/sortData";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import BaseTable from "@/components/Tables/BaseTable.vue";

export default {
  name: "staff-table",
  components: {
    BaseTable, TablePagination,
    Preloader,
    CustomInput,
  },
  setup() {
    const store = useStore();
    const countOnPage = ref(15),
        page = ref(1),
        isPreloader = ref(false),
        searchParams = reactive({
          nameField: "",
        });
    const companies = computed(() => store.state.company.companies),
        rowData = computed(() => {
          return companies.value.map((company) => {
            return {
              id: company.id,
              name: company.name,
              edit: company.id,
            };
          });
        });

    onBeforeMount(() => {
      store.dispatch("company/getCompanies");
    });

    const organisationFields = [
      {
        field: "name",
        headerName: "Название",
        link: true,
        dest: "company",
      },
      {
        field: "edit",
        headerName: "",
        dest: "company",
      },
    ];

    const pageDataStart = ref(0);
    const updatePagination = ref(false);
    const totalResults = ref();

    const shownData = computed(() => {
      let data = rowData.value ?? [];

      if (data.length) {

        if (searchParams.nameField && searchParams.nameField.length >= 3) {
          data = data.filter((el) => el.name.toLowerCase().includes(searchParams.nameField.toLowerCase()));
        }

        data = sortData(data, 'name', 'up');

        totalResults.value = data.length;
        data = data.slice(pageDataStart.value, pageDataStart.value + countOnPage.value);
      }

      return data;
    });

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    watch(
        () => [searchParams.nameField],
        () => {
          totalResults.value = 0;
          pageDataStart.value = 0;
          updatePagination.value = !updatePagination.value;
        },
    )

    return {
      countOnPage,
      isPreloader,
      page,
      changePage,
      companies,
      searchParams,

      rowData,
      totalResults,
      shownData,
      organisationFields,
      updatePagination,
    };
  },
};
</script>

<style lang="scss" scoped>
.no_results {
  padding: 40px 10px;
}

.filter_block {
  display: flex;
  flex-direction: column;
}

.filter_block .form-group {
  width: 70%;
}

@media (max-width: 768px) {
  .filter_block .form-group {
    width: 100%;
  }
}
</style>
