<template>
    <div class="view-container">
    <div class="card">
      <div class="card-header card-header-divider">
        <div class="card-title">Компании</div>
        <div class="tools">
          <router-link to="/company/create" class="btn btn-accent"
            >Создать компанию</router-link
          >
        </div>
      </div>
      <div class="card-body">
        <organizations-table/>
      </div>
    </div>
  </div>
</template>

<script>
import OrganizationsTable from '../../components/Tables/OrganizationsTable.vue';
export default {
  components: { OrganizationsTable },
    name: "organizations"
};
</script>

<style>
</style>